import React from "react";

import Layout from "/src/components/layouts/Layout";
import SectionLayoutGrid from "/src/components/layouts/SectionLayoutGrid";

import {Title} from "../components/styled/typography/Typography";
import Col from "../components/styled/grid/Col";
import {ButtonContainer, ButtonLink} from "../components/styled/button/ButtonComponents";

const NotFoundPage = () => {
    return (
        <Layout lang="en" seo={{title: "404 | Balasys", description: "Balasys 404 error page.", isIndexable: false}}>
            <SectionLayoutGrid customBackground="middle">
                <Col>
                    <Title color="white" textAlign="center" marginBottom="medium">
                        404
                    </Title>
                    <Title color="white" textAlign="center">
                        Not found
                    </Title>
                </Col>
                <Col grid center>
                    <ButtonContainer>
                        <ButtonLink to={"/"} color="primary-brand">
                            Back
                        </ButtonLink>
                    </ButtonContainer>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    )
}

export default NotFoundPage
