import React from "react";

import {Background, LayoutGrid} from "../styled/grid/Grids";

const SectionLayoutGrid = ({children, customBackground, id, start, customPadding, customMinHeight, image, contact, wide, customGap, solutions, solutionsMobile}) => {

    return (
        <Background id={id} customBg={customBackground} image={image}>
            <LayoutGrid alignStart={start} customPadding={customPadding} minHeight={customMinHeight} contactMargin={contact} wide={wide} customGap={customGap} solutionsMobile={solutionsMobile}>
                {children}
            </LayoutGrid>
        </Background>
    )
}

export default SectionLayoutGrid;